import { ElMessage } from "element-plus";

// warning
export function ElMessageWarning(msg:string) {
  ElMessage({
    showClose: true,
    message: msg,
    type: 'warning',
  })
}

// success
export function ElMessageSuccess(msg:string) {
  ElMessage({
    showClose: true,
    message: msg,
    type: 'success',
  })
}

// error
export function ElMessageError(msg:string) {
  ElMessage({
    showClose: false,
    message: msg,
    type: 'error',
  })
}
