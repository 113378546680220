
 import { App } from 'vue';

 /**
  * 配置Vue错误处理功能
  */
 
//  function vueErrorHandler(err: Error, vm: any, info: string) {
//    // err：具体错误信息
//   // vm：当前错误所在的Vue实例
//   // info：Vue特定的错误信息，错误所在的生命周期钩子
//  }
 
 /**
  * 配置脚本错误处理功能
  */
 export function scriptErrorHandler(
   event: Event | string,
   source?: string,
   lineno?: number,
   colno?: number,
   error?: Error,
 ) {
  // message：错误消息（字符串）
  // source：引发错误的脚本的URL（字符串）
  // lineno：发生错误的行号（数值)
  // colno：发生错误的行的列号（数值）
  // error：错误对象（对象）
 }
 
 /**
  * 配置 Promise 错误处理函数
  */
 function registerPromiseErrorHandler() {
   window.addEventListener(
     'unhandledrejection',
     function (event) {
       // event.reason 错误消息
     },
     true,
   );
 }
 
 /**
  * 配置监控资源加载错误处理功能
  */
 function registerResourceErrorHandler() {
   // 监控资源加载错误(img,script,css,and jsonp)
   window.addEventListener(
     'error',
     function (e: Event) {
      //待测试
     },
     true,
   );
 }
 
 /**
  * 配置全局错误处理
  * @param app
  */
export function setupErrorHandle(app: App) {

    app.config.errorHandler = (err, vm, info) => {
      // err：具体错误信息
      // vm：当前错误所在的Vue实例
      // info：Vue特定的错误信息，错误所在的生命周期钩子
      
    };
   
    window.onerror = scriptErrorHandler;
   
    registerPromiseErrorHandler();

    registerResourceErrorHandler();
 }
 
