import { translateDocDownload } from "~/api/wordcloud";

export default {
  message: {
    home: '主页',
    login: '登录',
    accountLogin: '账号登录',
    pleaseEnterAccountName: '请输入账号名',
    pleaseEnterYourPassword: '请输入登录密码',
    exitSystem: '退出系统',
    reminder: '温馨提示',
    exitSystemTips: '是否确认退出系统?',
    pleaseEnterNamePassword: '请输入正确的用户名和密码',

    // menu
    wordCloud: '词云',
    commentRanking: '评论排名',
    setup: '设置',
    setVocabulary: '设置词汇',

    // 词云
    selectTime: '请先选择时间段',
    wordcloudClickSearchGetData: '请点击搜索，获取数据',
    wordcloudNoDataFound: '未搜索到数据',
    wordcloudHeadwordSymbols: '中心词含有特殊符号，请先删除',
    wordcloudNotSupportSymbols: '不支持输入特殊符号，只能输入中文、英文、数字',

    wordcloudHeadword: '中心词',
    wordcloudPleaseFillOut: '请填写',
    wordcloudPlatform: '平台',
    wordcloudPleaseChoose: '请选择',
    wordcloudSelectAll: '全选',
    wordcloudBrand: '品牌',
    wordcloudPeriod: '时间段',
    wordcloudStartingTime: '开始时间',
    wordcloudEndTime: '结束时间',
    wordcloudNumberWords: '词数量',
    reset: '重置',
    search: '搜索',
    keywords: '关键字',

    // 评论
    ECProductIDsTips: '用逗号隔开已输入的ids',
    ECProductIDsTipsReg: '支持输入仅数字的id（用逗号隔开已输入的ids）',
    new: '新增',
    filterWord: '过滤词',
    username: '用户名',
    date: '日期',
    actions: "操作",
    delete: '删除',


    addFilter: '添加过滤词',
    "confirm": "确认",
    "cancel": "取消",
    deleteConfirm: '删除确认',
    deleteConfirmTips: '确定要删除吗？',
    clickQueryData: '点击搜索按钮查询数据',
    original: '原文',
    heat: '热度',


    filter: '过滤官方账号',
    details: '点击查看详情',
    comment: '评论',
    sort: '分类',
    contact: '联系我们',
    numLimit: '请在1-100之间选择数字',
    placeholderNum: '请输入词数量',
    buzz: '品牌声量',
    productCode: '产品编码',
    brand: '品牌',
    productDesc: '产品描述',
    category: '品类',
    SBU: 'SBU',
    year: '年份',
    season: '季节',
    gender: '性别',
    color: '颜色',
    colorGroup: '颜色组',
    score: '得分',
    recommendedNumber: '推荐个数',
    intelligentRecommendation: '智能推荐',
    selectProduct: '请至少选择一款产品',
    recommend: '推荐',
    pleaseInput: '请输入',
    copywriting: '文案',

    // 文案
    activityContent: '活动内容',
    productName: '产品名称',
    productNamePlaceholder: '产品名称描述',
    eventTheme: '活动主题',
    eventThemePlaceholder: '活动主题描述',
    productFeatures: '产品特点',
    productFeaturesPlaceholder: '其他产品特点（限50字内）',
    targetAudience: '目标人群',
    populationAge: '人群年龄',
    populationAgePlaceholder: '数值或描述',
    crowdInterests: '人群兴趣',
    crowdInterestsPlaceholder: '人群兴趣 (限50字内)',
    otherFeatures: '其他特征',
    otherFeaturesPlaceholder: '其他人群特征 (限50字内)',
    articleRequirements: '文章要求',
    copywritingTone: '文案语气',
    wordNumber: '文案字数',
    wordNumberPlaceholder: '至多字数（最多1000字）',
    deliveryChannels: '投放渠道',
    generateContent: '生成内容',
    generateTokenNumber: '产生token数',
    modificationComments: '修改意见',
    modificationCommentsPlaceholder: '输入限制在100字以内',
    adjustingContent: '调整内容',
    lively: '活泼',
    amusing: '有趣',
    official: '官方',
    polite: '客气',
    casual: '休闲',
    introverted: '内敛',
    passionate: '激情',
    literary: '文艺',
    solemn: '庄重',
    male: '男',
    female: '女',
    unlimited: '不限',
    copywritingTips: '不要输入公司机密信息，使用中的任何问题，可点击右上角问题反馈按钮，进行反馈，谢谢！',
    copywritingTips2: '不要透露任何关于员工、承包商、消费者等的个人信息',
    copywritingTips3: '不要上传任何VF知识产权或使用现有VF知识财产创建任何新材料',
    redbook: '小红书',
    weibo: '微博',
    officialAccount: '公众号',
    textMessage: '短信',
    wecom: '企业微信',
    advertisement: '广告',
    shortvideo: '短视频',
    creativeWriting: '创意文',
    instagram: 'Instagram',
    facebook: 'Facebook',
    livestream: '直播',
    waitingMessage: '正在生成文案，请稍等',

    // 问题反馈
    feedbackButton: '问题反馈',
    feedbackPlaceholder: '请输入使用过程中遇到的问题，或者是想要的功能，收到后我们会尽快处理',
    select: '请选择',
    "submitNow": "提交",
    getAuthenticationUrl: "获取认证地址中",
    customLogin: "验证用户中",
    authenticateFail: "授权失败，即将返回登录页",
    SEOOpti: 'SEO 优化',
    hotWords: '热词',
    seoHotWordsPlaceholder: '可输入SEO热词，多个词语用逗号隔开',
    refPicture: '参考图片',
    translate: '翻译',
    translateInputPlaceholder: '输入原文',
    translateFromLanguage: '源语言',
    translateToLanguage: '目标语言',
    translateTone: '语气',
    translateAuto: '自动检测',
    translateDocUpload: '上传文档，支持.pptx,.docx,.pdf',
    translateDocDownload: '文档翻译历史',
    translateDocUploadBt: '文档翻译上传',
    translateDocDownloadBt: '文档翻译记录',
    noDate: '无数据',
    question: '提问',
    questionInputPlaceholder: '输入问题',
    imageInputPlaceholder: '输入描述',
    "downloadAll": "一键下载",
    imageWaitingMessage: '正在生成图片，请稍等',
    downloadImages: '下载图片',
    image: '图片',
    askQuestion: '提问',
    imageGeneration: '图片生成',

    batchCopyGenerationTemplate: '批量文案生成模板',
    templateDownload: '模板下载',
    upload: '上传',
    generateTemplatesBatchTranslation: '批量翻译生成模板',
    batchTips:'批量任务仅保留1年，请及时下载',
    taskName: '任务名称',
    filePath: '文件路径',
    number: '序号',
    taskTyps: '任务类型',
    creator: '创建者',
    creationTime: '创建时间',
    taskStatus: '任务状态',
    download: '下载',
    refresh: '刷新',
    pleaseEnterName: '请输入名称',
    uploadCopywriting: '上传文案',
    uploadTranslation: '上传翻译',
    batchTasks: '批量任务',
    choose: '选择',
    translation: '翻译',
    inProgress: '进行中',
    error: '错误',
    completed: '完成',

    knowledge: '知识问答',
    searchResult: '找到相关结果如下',
    relatedContentLink: '相关内容原文',
    searchSummary: '回答如下',

      //Chat Assistant
      chatAssistant:'智能客服',
      send:'发送',
      topic:'主题',
      chatnotice1:'新的对话已开启',
      chatnotice2:'之前的对话内容不会被引用',
         //upload Document
     uploadDocument:'文档上传',
     inputDocument:'输入文件名搜索',
     uploadFileName:'文件名',
     uploadTips:"已存在同名文件，上传此文件将覆盖原文件",
     
     modelTips: "翻译至日韩文可使用GPT4o1模型(页面右上角切换）以达到更好质量"
  }
}