<template>
  <div id="app">
    <el-config-provider namespace="ep">
      <router-view v-if="isRouterAlive"/>
    </el-config-provider>
  </div>
</template>
<script setup lang="ts">
import { nextTick, provide, ref } from 'vue';

const isRouterAlive = ref<boolean>(true);

provide('reload', reload);

function reload() {
  isRouterAlive.value = false;
  nextTick(() => {
    isRouterAlive.value = true;
  });
}
</script>

<style scoped lang="scss">

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}

.ep-container {
  height: 100%;
}

.layout-container {
  background-color: #F0F2FD;
}

</style>
