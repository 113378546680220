/*
 * @Author: 登录时长设置 目前是12小时
 * @Date: 2022-10-20 14:04:08
 * @LastEditTime: 2022-10-28 12:44:07
 * @LastEditors: daxiadeMacBook-Pro.local
 * @Description: In User Settings Edit
 * @FilePath: /social-analysis-portal-frontend/src/utils/loginDuration.ts
 */
import { UserInfo } from '~/interface/common'
import { rmUserInfo,getUserInfo,rmToken } from './auth'

export function loginDuration() {
  const userinfo = getUserInfo() 
  if (userinfo) {
    const hours = 12 * 60 * 60 * 1000
    // 当前时间
    const curGetTime = new Date().getTime()
    const oldGetTime = userinfo as any
    if (curGetTime - oldGetTime.getTime >= hours) {
      rmUserInfo()
      rmToken()
      return true
    }
  }
}