import { createI18n } from 'vue-i18n';
import zh_CN from './zh_CN'
import en_US from './en_US'
import zh_HK from './zh_HK';
import ja_JP from './ja_JP';
import kr_KR from './kr_KR';
import { App } from 'vue';
import { useGlobalConfig } from '~/store';
const globalConfig = useGlobalConfig()

// 1. For .vue html 
//    {{$t('message.login')}}
//
// 2. For .ts 
//    import { i18n } from '@/locales';
//    i18n.global.t('message.psdRules')
//
// 3. For .vue setup
//    import { useI18n } from 'vue-i18n';
//    const i18n = useI18n();
//    i18n.t('message.psdRules')

const messages = {
  zh_CN,
  en_US,
  zh_HK,
  ja_JP,
  kr_KR
}

export const i18n = createI18n({
  legacy: false,
  // 默认 英文
  locale: globalConfig.getLanguage(),
  globalInjection: true,
  messages
});

export function setupLanguage(app: App<Element>) {
  app.use(i18n);
} 



