
import Lockr from 'lockr'

const userInfo = import.meta.env.MODE === 'prod' ? 'userInfo': 'userInfo-dev'

export function setUserInfo(data: any) {
  Lockr.set(userInfo,data);
}

export function getUserInfo() {
  return Lockr.get(userInfo);
}

export function rmUserInfo() {
  Lockr.rm('model')
  return Lockr.rm(userInfo);
}

export function setToken(token: string) {
  Lockr.set('token',token);
}

export function getToken() {
  return Lockr.get('token');
}

export function rmToken() {
  Lockr.rm('token');
}
