
import { createApp } from 'vue'
import App from './App.vue'
import "~/styles/index.scss";

import { setupRouter } from './router';
import { setupErrorHandle } from './logics/error-handle';
import { setupLanguage } from './locales';

import 'element-plus/es/components/message-box/style/index'
import 'element-plus/es/components/message/style/index'
import { useStore } from 'vuex';
const store = useStore();
import emitter from  './utils/mitt'
async function bootstrap() {
  const app = createApp(App);

  
  app.directive("scrollBottom", {
      updated(el) {

       // 这里的el即是绑定指令处的dom元素
        el.scrollTo({
          top: el.scrollHeight - el.clientHeight,
          behavior: "smooth"
        })
      }
    })
  app.directive('parseHtml', {
    mounted(el,binding,vnode){
      const htmlContent = binding.value;
      // console.log(htmlContent)
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;
   
      // 遍历临时DOM中的所有元素，并将图片包装成链接
      const imgElements = tempDiv.querySelectorAll('img');
      imgElements.forEach((img) => {
        const link = document.createElement('div');
        link.appendChild(img.cloneNode(true));
        link.className='imgbox'
        link.onclick=()=>{
          // console.log(2)
        }
        img.replaceWith(link);
      });
      el.addEventListener('click', (event) => {     
        if (event.target.tagName.toLowerCase() === 'img') {
            let imgSrc = event.target.src;
            emitter.emit('imgClick',imgSrc)
        }
      })
      // 将解析后的内容设置回元素
      el.innerHTML = tempDiv.innerHTML;
      
    }
   
    
  });
  
  // i18n
  setupLanguage(app);
  
  // 配置路由
  setupRouter(app);
  
  // 配置全局错误处理
  setupErrorHandle(app);

  app.mount('#app');
}

bootstrap();
